import React from "react";
import sectionIcon from "../images/section-icon.png";
const Contact = () => {
  return (
    <section className="contact-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-heading">
              <div className="section-icon">
                <img src={sectionIcon} alt="section-icon" />
              </div>
              <h2 className="section__title">Get in Touch With Us</h2>
              <p className="section__meta">Write a message</p>
              <p className="section__desc">
                We would love to hear from you! If you have any questions about
                our programs or would like to learn more about how you can
                support our work, please don’t hesitate to get in touch with us.
                Thank you for your interest in our organization. We look forward
                to hearing from you and working together to make a positive
                impact in our community.
              </p>
              <ul className="section__list">
                <li>
                  <a href="#none">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/PDJJKS/" target="blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@purbadhansarajotejanakalya1781">
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="#none">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-shared">
              <form
                action="https://getform.io/f/fddadbbb-d605-4f27-afb0-68989b840849"
                method="post"
              >
                <div className="row">
                  <div className="col-lg-6 col-sm-6 form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Full Name"
                    />
                  </div>

                  <div className="col-lg-6 col-sm-6 form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                  </div>

                  <div className="col-lg-12 form-group">
                    <input
                      className="form-control"
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                    />
                  </div>

                  <div className="col-lg-12 col-sm-12 form-group">
                    <textarea
                      className="textarea"
                      name="message"
                      placeholder="Write a Message"
                    ></textarea>
                  </div>
                  <input
                    type="hidden"
                    name="_gotcha"
                    style={{ display: "none !important" }}
                  />
                  <div className="col-lg-12 col-sm-12">
                    <button className="theme-btn submit__btn" type="submit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row contact-detail-action">
          <div className="col-lg-4">
            <div className="contact-item contact-item1">
              <h3 className="contact__title">About</h3>
              <p className="contact__desc">
                We are an Indian NGO dedicated to improving the lives of people.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contact-item contact-item2">
              <h3 className="contact__title">Address</h3>
              <p className="contact__desc">
                Dhansara Jote, Ranidanga, Siliguri, Darjeeling
                <br />
                (W.B) - 734012
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contact-item contact-item3">
              <h3 className="contact__title">Contact</h3>
              <p className="contact__desc">
                info@pdjjks.org <br />
                +91-9932238042
                <br />
                +91-9933086710
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
